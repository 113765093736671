import { Navigate } from "react-router-dom";

class HttpService {
  savedHeaders = null;
  currentLocale = 'en';

  constructor() {

  }

   async headers() {
    try {
      const userToken = window.localStorage.getItem('userToken');
      let headers = {};

      headers.LOCALE = this.currentLocale;

      if (userToken) {
        headers.Authorization = `Bearer token=${userToken}`;
        this.savedHeaders = headers;
      }
      return headers;
    } catch (error) {
      return {};
    }
  }

  resetToken() {
    window.localStorage.removeItem('userToken');
    this.savedHeaders = null;
  }

  async send(method, request) {
    const {url, data, formData, responseAsBlob} = request;

    // const headers = this.savedHeaders
    //   ? this.savedHeaders
    //   : await this.headers();
    const headers = await this.headers();
    const fetchBody = {
      method: method,
      headers: Object.assign(
        {
          'Content-Type': 'application/json',
        },
        headers,
      ),
    };

    if (formData) {
      fetchBody.headers =  Object.assign(
        fetchBody.headers,
        {
          Accept: 'multipart/form-data',
          'Content-Type': 'multipart/form-data',
        }
      );
      fetchBody.headers =  headers;
    }

    if (method !== 'GET') {
      fetchBody.body = formData ? data : JSON.stringify(data);
    }

    return fetch(url, fetchBody)
      .then(async response => {
        if (response.status.toString().startsWith('2')) {
          return responseAsBlob ? response.arrayBuffer() : response.json();
        } else {
          if (response.status === 401) {
            this.resetToken();
            <Navigate to="/login" replace />;
            return Promise.resolve();
          }
          const bodyText = await response.text();
          let result = bodyText;
          try {
            result = JSON.parse(bodyText);
          } catch (error) {}
          return Promise.reject(result);
        }
      })
      .catch(error => {
        console.error(error);
        return Promise.reject(error);
      });
  }

  async sendGet(url) {
    return this.send('GET', {url});
  }

  async sendPost(request) {
    return this.send('POST', request);
  }

  async sendPut(request) {
    return this.send('PUT', request);
  }

  async sendDelete(request) {
    return this.send('DELETE', request);
  }

  async postWithProgress(request, onProgress) {
    const {url, data} = request;

    const headers = await this.headers();
    let headerName = Object.keys(headers)[0];
    let headerValue = headers[headerName];
    return new Promise((resolve, reject) => {
      let xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      if (headerName && headerValue) {
        xhr.setRequestHeader(headerName, headerValue);
      }
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.onload = function () {
        if (xhr.status === 200) {
          // onProgress(xhr, xhr);
          resolve(JSON.parse(xhr.response));
        } else {
          reject(xhr);
        }
      };
      xhr.onerror = function () {
        reject(xhr);
      };
      xhr.upload.onprogress = function (e) {
        if (e.lengthComputable) {
          onProgress(e.loaded, e.total);
        }
      };
      xhr.send(JSON.stringify(data));
    });
  }
}

const http = new HttpService();

export default http;
