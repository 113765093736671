import React, { useContext, createContext, useState } from "react";
import "./App.scss";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
  useOutlet,
} from "react-router-dom";
import Loader from "components/Loader";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Layout from "./components/Layout";
import LoginPage from "./pages/Login";
import DashboardLayout from "./components/DashboardLayout";
import { AuthProvider, useAuth } from "./hooks/useAuth";
import { fetchDictionary } from "./actions/global";
import { useStore, useSelector, useDispatch } from "react-redux";

import Button from "./components/Button";
const ModerationPage = React.lazy(() => import("./pages/Moderation"));
const StartPage = React.lazy(() => import("./pages/Start"));
const ChatPage = React.lazy(() => import("./pages/Chat"));
const EditPlace = React.lazy(() => import("./pages/EditPlace"));
const ListForm = React.lazy(() => import("./pages/ListForm"));
const EpisodeForm = React.lazy(() => import("./pages/EpisodeForm"));
const Lists = React.lazy(() => import("./pages/Lists"));
const Communities = React.lazy(() => import("./pages/Communities"));
const EditCommunity = React.lazy(() => import("./pages/EditCommunity"));
const Episodes = React.lazy(() => import("./pages/Episodes"));
const TalkForm = React.lazy(() => import("./pages/TalkForm"));
const Talks = React.lazy(() => import("./pages/Talks"));
const EmailTemplates = React.lazy(() => import("./pages/EmailTemplates"));
const PushTemplates = React.lazy(() => import("./pages/PushTemplates"));
const PushTemplateForm = React.lazy(() => import("./pages/PushTemplateForm"));
const SundaySerendipities = React.lazy(() =>
  import("./pages/SundaySerendipities")
);
const EmailTemplateForm = React.lazy(() => import("./pages/EmailTemplateForm"));

const PlaceForm = React.lazy(() => import("./pages/PlaceForm"));

function RequireAuth({ children }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  const outlet = useOutlet();
  return (
    <>
      {/* <h3>Protected</h3>
    <Link to="/dashboard/moderation">START</Link> */}
      {outlet}
    </>
  );
}

function InnerPage() {
  const navigate = useNavigate();
  return (
    <>
      <h3>Protected</h3>
      <Link to="/dashboard/moderation">START</Link>
      <Button
        onClick={() => {
          navigate("/dashboard/moderation");
        }}
      >
        Start moderation
      </Button>
      <Button type="black">See logs</Button>
    </>
  );
}

function App({cable}) {
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    dispatch(fetchDictionary());
  }, []);

  const location = useLocation();

  return (
    <AuthProvider>
      {/* <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames={"next"}
          timeout={300}
        > */}
      <Routes location={location}>
        <Route element={<Layout />}>
          <Route index element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <DashboardLayout />
              </RequireAuth>
            }
          >
            <Route
              index
              element={
                <React.Suspense fallback={<Loader />}>
                  <StartPage cable={cable}/>
                </React.Suspense>
              }
            />
            <Route
              path='chat'
              element={
                <React.Suspense fallback={<Loader />}>
                  <ChatPage cable={cable}/>
                </React.Suspense>
              }
            />
            <Route
              path="moderation"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ModerationPage />
                </React.Suspense>
              }
            />
            <Route
              path="communities"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Communities />
                </React.Suspense>
              }
            />
            <Route
              path="communities/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EditCommunity />
                </React.Suspense>
              }
            />
            <Route
              path="lists"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Lists />
                </React.Suspense>
              }
            />
            <Route
              path="lists/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <ListForm />
                </React.Suspense>
              }
            />
            <Route
              path="lists/:list_id/places/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PlaceForm />
                </React.Suspense>
              }
            />
            <Route
              path="episodes/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EpisodeForm />
                </React.Suspense>
              }
            />
            <Route
              path="episodes"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Episodes />
                </React.Suspense>
              }
            />
            <Route
              path="talks"
              element={
                <React.Suspense fallback={<Loader />}>
                  <Talks />
                </React.Suspense>
              }
            />
            <Route
              path="talks/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <TalkForm />
                </React.Suspense>
              }
            />
            <Route
              path="sunday_serendipities"
              element={
                <React.Suspense fallback={<Loader />}>
                  <SundaySerendipities />
                </React.Suspense>
              }
            />
            <Route
              path="email_templates"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EmailTemplates />
                </React.Suspense>
              }
            />
            <Route
              path="email_templates/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <EmailTemplateForm />
                </React.Suspense>
              }
            />
            <Route
              path="push_templates"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PushTemplates />
                </React.Suspense>
              }
            />
            <Route
              path="push_templates/:id"
              element={
                <React.Suspense fallback={<Loader />}>
                  <PushTemplateForm />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
      {/* </CSSTransition>
      </TransitionGroup> */}
    </AuthProvider>
  );
}

export default App;
